import { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";

const TDLDetails = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 60000);
  }, []);

  return (
    <div>
      {loader ? (
        <div className="row">
          <div className="offset-5" style={{ marginTop: 350 }}>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
          </div>
        </div>
      ) : (
        <div>
          <p>
            Something Went Wrong! Your Server Configuration Not Compitalbe With Your Current Version Please Upgrade
            Your Server Configuration and Version.
          </p>
        </div>
      )}
    </div>
  );
};

export default TDLDetails;
