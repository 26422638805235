import React from 'react'
import notFound from "./server-error.svg"

const NotFound = () => {
  return (
    <div className='w-100'>
      <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
        <img src={notFound} alt="404" className="img-fluid" />
        <h2 className="mt-5 fs-1 fw-bold">Oops!</h2>
        <p className="fs-3">Sorry, page not found!</p>
      </div>
    </div>
  )
}

export default NotFound